const HREF = window.location.href;

(function(){

    //Show Password
    function showPassword(inputelement, element){

        //Check if password input is not empty
        if(inputelement.value !== ''){

            //Check conditional for show or hide password
            if(element.getAttribute('data-show') === 'true'){

                //Change Conditional for show/hide password
                element.setAttribute('data-show', 'false');

                //Actived Show Password method
                inputelement.setAttribute('type', 'text');

                //Change action
                element.textContent = 'hide';


            }else{

                //Change Conditional for show/hide password
                element.setAttribute('data-show', 'true');

                //Actived Show Password method
                inputelement.setAttribute('type', 'password');

                //Change action
                element.textContent = 'show';

            }

        }

        return;

    }

    //Create Error Message
    function createErrorMessageElement(idname, errorMessage, removespan = 5000){

        //Check when span exist
        if(!document.querySelector('span.errorMessage')){
            
            //Get Element to Apeend
            let input = document.getElementById(idname);

            //Add Error Message Class for the input
            input.classList.add('is-invalid');

            //Create Element
            let spanError = document.createElement('span');
    
            //Set Attribute for the element
            spanError.setAttribute('class', 'errorMessage order-3 invalid-feedback');
            spanError.innerText = errorMessage;

            //Create Element After Input
            input.after(spanError);

            //Remove Span Element
            setTimeout(() => {

                //Remove ErrorMessage class from the input
                input.classList.remove('is-invalid');

                //Remove Span Error Message
                document.querySelector('span.errorMessage').remove();

            }, removespan);

            return;

        }

        return;

    }

    //Check Login Route, User CreatePass Route
    if(HREF.includes('/login') || HREF.includes('/usercreatepass')){

        //Check Error Message
        document.addEventListener('DOMContentLoaded', function(){

            //Get Error List Element
            let errorList = document.querySelector('.alert-danger ul');

            //Check if the Error Element exist
            if(errorList){

                setTimeout(function(){

                    return document.querySelector('.alert-danger').remove();

                }, 5000);

            }

            return;

        });

        //Show Password
        document.querySelector('.showpass').addEventListener('click', function(e){

            //Check if password input is not empty
            showPassword(document.getElementById('mailpassword'), this);
         
        });

        //Show Login And Recuperar Senha
        if(document.querySelector('.forgot_pass')){

            $('.forgot_pass').on('click', function(){

                //Get Form Indicate
                let formHide = this.getAttribute('data-formhide');
                let formShow = this.getAttribute('data-formshow');

                //Hide Form
                if(!document.querySelector(`.${formHide}`).classList.contains('d-none')){

                    document.querySelector(`.${formHide}`).classList.add('d-none');
                    document.querySelector(`.${formShow}`).classList.remove('d-none');

                }else{

                    document.querySelector(`.${formHide}`).classList.remove('d-none');
                    document.querySelector(`.${formShow}`).classList.add('d-none');

                }

            });

        }
  
    }

    //Check Admin Route
    if(HREF.includes('/admin')){

        //Menu - Submenu Actions
        function showAndHideSubmenu(menu){

            //Get submenu
            let submenu = menu.nextElementSibling;
            
            //Check Submenu 
            if(submenu.classList.contains('d-none')){

                //Show
                submenu.classList.remove('d-none');

            }else{

                //Hide
                submenu.classList.add('d-none');

            }

        }

        //Active Nav Itemn
        function activeNavItem(navItemIndex, navItemsList){


            //Remove Acive Class
            navItemsList.forEach((value, index) => {
                    
                //Get Children
                let navItemChildrens = [...value.children];

                //Get Href Element
                let elementhref = navItemChildrens.find((valelement) => valelement.nodeName === 'A');

                let subMenu = navItemChildrens.find((valelement) => valelement.nodeName === 'UL');

                //Active Routine
                if(!elementhref.classList.contains('active') && 
                index === navItemIndex
                ){

                    elementhref.classList.add('active');

                    if(subMenu !== undefined){

                        subMenu.classList.add('show');

                    }

                }else{

                    elementhref.classList.remove('active');

                    if(subMenu !== undefined){

                        subMenu.classList.remove('show');

                    }

                }
            
            });


        }

        //All Nav Items
        let navItemsList = [...document.querySelector('.sidebarmenu').children];

        //Get Current
        let path = window.location.pathname;

        //All Modules
        let modules = ['user', 'pages', 'navigation', 'media', 'content', 'contacts', 'workwithus', 'feedconnect'];

        window.addEventListener('load', function(){

            //Set Indicate Path
            let pathSplit = path.split('/');

            //Find Path
            let pathName = pathSplit.find((value) => modules.includes(value));

            let pathIndicate = pathName !== undefined ? pathName : 'admin';

            let navItem = navItemsList.find((value) => value.getAttribute('data-indicate') === pathIndicate);

            //Index Current target
            let navItemIndex = navItemsList.indexOf(navItem);

            //Active
            activeNavItem(navItemIndex, navItemsList);

        });

        //Add Active Class for Menu
        document.querySelector('.sidebarmenu').addEventListener('click', function(element){

            //Get Target
            let navItemHref = element.target;

            //Get Parent Element
            let navItem = "";

            switch(navItemHref.nodeName){

                case 'A':
                    navItem = navItemHref.parentElement;
                break;

                case 'SPAN':
                case 'I':    
                    navItem = navItemHref.parentElement.parentElement;
                break;

            }

            //Index Current target
            let navItemIndex = navItemsList.indexOf(navItem);

            //Active
            activeNavItem(navItemIndex, navItemsList);
            
            return;

        });

    }

    //Check User CreatePass Route
    if(HREF.includes('/usercreatepass')){

        //Get Password
        let inputPassword = document.getElementById('mailpassword');

        //Submit Button
        let submitBtn = document.getElementById('submitcreatepass');

        //Check Password
        function checkPassword(element, inputpassword){

            //Confirm Password
            let confirmPassword = element.target.value.trim();

            if(confirmPassword !== inputpassword){

                //Create Error Message
                if(confirmPassword !== ''){

                    createErrorMessageElement('confirmpassword', 'O confirm password e password são diferentes');

                }
               
                //Button Disable
                submitBtn.setAttribute('disabled', true);

            }else{

                console.log(submitBtn);

                //Button Enable
                submitBtn.removeAttribute('disabled');

            }
            
        }

        //Disabled BTN
        document.addEventListener('DOMContentLoaded', function(){

            //Button Disable
            submitBtn.setAttribute('disabled', true);

        });

        //Focus In
        document.getElementById('confirmpassword').addEventListener('focusin', function(element){

            checkPassword(element, inputPassword.value.trim());

        });

        //Focus Out
        document.getElementById('confirmpassword').addEventListener('focusout', function(element){

            checkPassword(element, inputPassword.value.trim());

        });

        //Show Confirm Password
        document.querySelector('.showconfirmpass').addEventListener('click', function(e){

            //Check if password input is not empty
            showPassword(document.getElementById('confirmpassword'), this);
         
        });

    }

})();
